<template>
  <div class="csn-loading-wrapper"><div class="csn-loading"></div></div>
</template>

<script>
import { LOADING_COMPONENT } from '@/constants'

export default {
  name: LOADING_COMPONENT,
}
</script>
